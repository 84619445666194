import ImgShow from './img-show'
import ImgSlide from './img-slide'

export default [{
  name: '图片展示',
  value: 'imgShow',
  data: ImgShow
}, {
  name: '图片轮播',
  value: 'imgSlide',
  data: ImgSlide
}]
