export default [{
  type: 'imgSlide',
  name: '图片轮播',
  value: [{
    url: 'https://www.baidu.com',
    image: 'static/img/theme1.jpg'
  },
  {
    url: 'https://qq.com',
    image: 'static/img/theme2.jpg'
  },
  {
    url: 'https://jd.com',
    image: 'static/img/theme3.jpg'
  }
  ],
  interval: 3000,
  style: {
    margin: '0px 0px 0px 0px',
    height: 250
  }
}]
