export default [{
  type: 'formList',
  name: '表单内容区',
  list: [],
  wgClassName: 'wg-formlist',
  style: {
    margin: '0px 20px 0px 20px',
    borderradius: 10,
    borderRadius: '10px',
    borderColor: '#409eff',
    borderwidth: 3,
    borderWidth: '3px',
    borderStyle: 'solid'
  }
}]
