export default [{
  type: 'hPicker',
  name: '横向滑动单选',
  value: '20',
  options: [
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40'
  ],
  label: {
    labelTitle: '年龄',
    labelPosition: 'left',
    labelwidth: 50,
    labelWidth: '50px'
  },
  fieldTypes: 'hPickerTypes',
  apiKey: 'age',
  itemWidth: 50,
  showNumber: 5,
  pickerStyle: {
    color: '#409eff',
    fontsize: 18,
    fontSize: '18px'
  },
  style: {
    margin: '0px 0px 0px 0px'
  }
}]
