export default [{
  type: 'date',
  name: '日期选择器',
  label: {
    labelTitle: '生日',
    labelwidth: 50,
    labelWidth: '50px',
    labelPosition: 'left'
  },
  apiKey: 'birthday',
  value: '',
  fieldTypes: 'dateTypes',
  style: {
    margin: '0px 0px 0px 0px'
  }
}]
