<template>
  <div class="home-wrapper">
    <page-header />
    <el-container class="left-container">
      <div class="form-edit-wrapper flex">
        <el-aside>
          <form-tools />
        </el-aside>

        <el-container class="center-container">
          <el-header> </el-header>
          <el-main> </el-main>
        </el-container>

        <el-aside class="right-container">
          <el-container>
            <el-header> </el-header>
            <el-main> </el-main>
          </el-container>
        </el-aside>
      </div>
    </el-container>
  </div>
</template>

<script>
import PageHeader from '@/components/Header'
import FormTools from '@/components/FormTools'

export default {
  name: 'Home',
  components: {
    PageHeader,
    FormTools
  }
}
</script>
<style lang="scss" scoped>
.home-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .left-container {
    height: calc(100% - 70px);
    .form-edit-wrapper {
      width: 100%;
      overflow: auto;
    }
  }
  .center-container {
    min-width: 440px;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
  }
  .right-container {
  }
}
</style>
