export default [{
  type: 'randomCode',
  name: '随机码',
  placeholder: '请输入验证码',
  showLabel: false,
  label: {
    labelTitle: '验证码',
    labelPosition: 'left',
    labelwidth: 66,
    labelWidth: '66px'
  },
  value: '',
  apiKey: 'randomCode',
  codeValue: '',
  style: {
    margin: '0px 0px 0px 0px',
    btnStyle: {
      fontFamily: 'Helvetica Neue',
      backgroundColor: '#409EFF',
      color: '#fff',
      fontSize: '20px',
      fontsize: 20,
      borderRadius: '5px',
      borderradius: 5
    }
  }
}]
