export default [{
  type: 'staticText',
  name: '文本描述',
  value: '这是一段文本',
  link: '',
  popupList: [],
  showPopup: false,
  backgroundImage: '',
  backgroundColor: '',
  style: {
    margin: '0px 0px 0px 0px'
  }
}]
