export default [{
  type: 'switch',
  name: '开关',
  value: false,
  label: {
    labelTitle: '是否有信用卡',
    labelPosition: 'left',
    labelwidth: 100,
    labelWidth: '100px'
  },
  fieldTypes: 'switchTypes',
  apiKey: 'creditCard',
  style: {
    margin: '0px 0px 0px 0px'
  }
}]
