export default [{
  type: 'phone',
  name: '手机号',
  placeholder: '请输入手机号',
  showLabel: false,
  label: {
    labelTitle: '手机号',
    labelPosition: 'left',
    labelwidth: 50,
    labelWidth: '50px'
  },
  value: '',
  apiKey: 'phone',
  codeValue: '',
  codeKey: 'verifyCode',
  showCode: true,
  style: {
    margin: '0px 0px 0px 0px',
    btnStyle: {
      backgroundColor: '#409EFF',
      color: '#fff',
      borderColor: '#409EFF',
      borderRadius: '5px',
      borderradius: 5
    }
  }
}]
