export default [
  {
    type: 'agreement',
    name: '用户协议',
    apiKey: 'agreement',
    value: false,
    isRequired: true,
    tipText: '我已阅读并同意',
    titleTexts: [
      {
        title: '《隐私权协议》',
        text: ''
      },
      {
        title: '《用户协议》',
        text: ''
      }
    ],
    style: {
      fontsize: 12,
      fontSize: '12px',
      color: '#333333',
      margin: '10px 0px 10px 0px'
    },
    agreementColor: '#3b9cfd'
  }
]
