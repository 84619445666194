export default [{
  type: 'videoPlay',
  name: '视频播放',
  videoAttr: {
    src: 'https://www.runoob.com/try/demo_source/movie.mp4',
    poster: 'https://www.runoob.com/images/logo.png',
    autoplay: false,
    loop: false,
    controls: true
  },
  style: {
    width: '100%',
    pxHeight: 200,
    height: '200px',
    margin: '0px 0px 0px 0px'
  }
}]
