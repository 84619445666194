export default [{
  type: 'input',
  name: '输入框',
  placeholder: '请输入姓名',
  showLabel: false,
  label: {
    labelTitle: '姓名',
    labelPosition: 'left',
    labelwidth: 50,
    labelWidth: '50px'
  },
  value: '',
  apiKey: 'name',
  fieldTypes: 'inputTypes',
  style: {
    margin: '0px 0px 0px 0px'
  }
}]
