export default [
  {
    type: 'splitLine',
    name: '分割线',
    value: 'solid',
    style: {
      borderColor: '#d3d3d3',
      borderStyle: 'solid',
      borderTopWidth: '1px',
      margin: '20px 0px 20px 0px'
    }
  }
]
