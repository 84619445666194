<template>
  <div class="form-tools-wrapper">
    <div class="flex flex-column flex-none components-title">
      <el-button :key="item.value" :type="item.value === widgetLevel2.value ? 'primary' : ''" @click="handleWidget(item)" round size="small" v-for="item in widgetLevel1">{{ item.name }}</el-button>
    </div>
    <ul class="flex-auto components-content">
      <div class="components-group" :key="level1.value" v-for="level1 in widgetLevel1" v-show="level1.value === widgetLevel2.value">
        <li :key="level2.value" v-for="level2 in level1.data">
          <h4 class="widget-title">{{ level2.name }}</h4>
          <draggable
            :clone="cloneData"
            :filter="level2.dragOnce ? '.disdraggable' : ''"
            :group="{ name: 'widget', pull: 'clone', put: false }"
            :sort="false"
            @end="dragEnd"
            ghostClass="ghost"
            tag="ul"
            v-model="level2.data"
          >
            <li :class="{ disdraggable: disFormList(level3) }" :key="level3.apiKey" class="form-edit-widget-label" v-for="level3 in level2.data">
              <img :alt="level3.name" :src="BASE_URL + 'static/img/widget/' + level1.value + '/' + level3.type + '.jpg'" width="100%" />
            </li>
          </draggable>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Draggable from 'vuedraggable'
import widgetLevel1 from '@/assets/js/widget'
import utils from '@/utils'
export default {
  components: {
    Draggable
  },
  data () {
    return {
      widgetLevel1,
      widgetLevel2: widgetLevel1[0]
    }
  },
  computed: {
    ...mapState({
      pageData: (state) => state.common.pageData,
      configTab: (state) => state.common.configTab
    })
  },
  methods: {
    handleWidget (item) {
      this.widgetLevel2 = item
    },
    dragEnd () {
      this.$store.commit('setDragWg', '')
    },
    disFormList (wgItem) {
      // 阻止组件嵌套
      if (!utils.hasKey(wgItem, 'list')) return false
      if (this.pageData.list) {
        return this.pageData.list.some((v) => {
          return v.type === wgItem.type
        })
      }
      return false
    },
    cloneData (obj) {
      const elKey = utils.getUuid()
      const newObj = utils.deepClone(obj)
      newObj.key = newObj.type + '_' + elKey
      this.$store.commit('setDragWg', newObj)
      return newObj
    },
    handleConfigSelect (value) {
      this.$store.commit('setConfigTab', value)
    },
    handlePreview () {
      this.$root.$emit('formDesign_savePage')
      const newWin = window.open(this.$api.previewUrl())
      const timer = setInterval(() => {
        newWin.postMessage(this.pageData, this.$api.previewUrl())
      }, 200)
      window.addEventListener(
        'message',
        (event) => {
          if (event.origin !== this.$api.previewOrigin()) return
          if (event.data === 'Received') clearInterval(timer)
        },
        false
      )
    },
    handleReset () {
      this.$store.commit('setSelectWg', {})
      // this.$store.commit('setPageData', utils.deepClone(pageConfigData.pageConfig))
    },
    handleSave () {
      this.$root.$emit('formDesign_savePage')
      utils.setLStorage('pageData', this.pageData)
      this.$alert('保存成功', { showClose: false })
    }
  }
}
</script>

<style lang="scss">
$primary-color: rgb(64, 158, 255);
$primary-color-alpha: rgba(64, 158, 255, 0.6);
$primary-background-color: #ecf5ff;
.form-tools-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .el-button {
    border: none;
    font-size: 14px;
    margin: 5px 0 0 0;
  }

  .widget-cate {
    padding: 8px 12px;
    font-size: 13px;
  }

  .ghost {
    background: #fff;
    border: 1px dashed $primary-color;

    &::after {
      background: #fff;
    }
  }

  .components-title {
    height: 100%;
    padding: 8px;
    box-shadow: 3px 0 3px 0px rgba(0, 0, 0, 0.08);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .components-content {
    height: 100%;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;

    .components-group {
      .widget-title {
        font-size: 16px;
        margin-bottom: 15px;
        margin-top: 30px;
      }

      & li:first-child .widget-title {
        margin-top: 0;
      }
    }
  }

  .form-edit-widget-label {
    width: 100%;
    font-size: 12px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333;
    border: 1px solid #fff;
    text-align: center;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
    user-select: none;
    cursor: move;
    border-radius: 4px;
    margin: 20px 0;
    transition: all 0.2s;

    img {
      vertical-align: middle;
    }

    &:hover {
      color: $primary-color;
      box-shadow: 0 0 6px 3px $primary-color-alpha;
    }

    &.disdraggable {
      cursor: no-drop;
    }
  }
}
</style>
